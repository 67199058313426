import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, filter, map, of } from 'rxjs';

import { AppState, Session, Language, FitnessCenter, BookingResult } from '../models';
import { ServerService } from '../services/server.service';

@Injectable()
export class AppStateService {
  private appState: AppState;

  constructor(private server: ServerService, private translate: TranslateService) {
    this.appState = {
      error: new BehaviorSubject<string | null>(null),
      loading: new BehaviorSubject(false),
      activationCode: new BehaviorSubject<string | null>(null),
      user: new BehaviorSubject<string | null>(null),
      loginGuid: new BehaviorSubject<string | null>(null),
      language: new BehaviorSubject('se'),
      date: new BehaviorSubject(moment().format('YYYY[-]MM[-]DD').toString()),
      fitnessCenters: new BehaviorSubject<FitnessCenter[]>([]),
      selectedFitnessCenter: new BehaviorSubject<FitnessCenter | null>(null),
      sessionsLoading: new BehaviorSubject(false),
      sessions: new BehaviorSubject<Session[]>([]),
      session: new BehaviorSubject<Session | null>(null),
      bookedSession: new BehaviorSubject<{ sessionGuid: string; bookingResult: BookingResult } | null>(null),
    };

    this.watchErrors();
    this.loadSessions();
  }

  setError(error: string): void {
    this.appState.error.next(error);
  }

  setActivationCode(activationCode: string): void {
    this.appState.activationCode.next(activationCode);
    this.loadFitnessCenters();
  }

  setUser(userGuid: string): void {
    this.appState.user.next(userGuid);
  }

  setLoginGuid(loginGuid: string): void {
    this.appState.loginGuid.next(loginGuid);
  }

  setLanguage(language: Language): void {
    switch (language) {
      case Language.Swedish: {
        this.appState.language.next(language);
        this.translate.use('se');
        break;
      }
      case Language.English: {
        this.appState.language.next(language);
        this.translate.use('en');
        break;
      }
      default: {
        this.appState.language.next(Language.Swedish);
        this.translate.use('se');
        break;
      }
    }
  }

  setFitnessCenter(fitnessCenter: FitnessCenter): void {
    this.appState.selectedFitnessCenter.next(fitnessCenter);
  }

  setSession(session: Session): void {
    this.appState.session.next(session);
  }

  clearBookedSession(): void {
    this.appState.bookedSession.next(null);
  }

  forwardOneDay(): void {
    const date = this.appState.date.getValue();
    this.appState.date.next(moment(date).add(1, 'days').format('YYYY[-]MM[-]DD').toString());
  }

  backwardOneDay(): void {
    const date = this.appState.date.getValue();
    this.appState.date.next(moment(date).subtract(1, 'days').format('YYYY[-]MM[-]DD').toString());
  }

  bookSession(session: Session, pin: string, id: string): void {
    const user = this.appState.user.getValue();
    const loginGuid = this.appState.loginGuid.getValue();
    this.appState.loading.next(true);
    this.server
      .bookSession(session, user, loginGuid, pin, id)
      .then((response) => {
        if (response.Data) {
          this.appState.loading.next(false);
          this.appState.bookedSession.next({ sessionGuid: session.TrainingSessionGUID, bookingResult: response.Data });
          this.loadSessions();
        } else if (response.Diagnostics) {
          this.appState.loading.next(false);
          this.appState.error.next(response.Diagnostics.Diagnostic.Description);
        }
      })
      .catch(() => {
        this.appState.error.next('Problem communicating with server');
        this.appState.loading.next(false);
      });
  }

  get loading(): Observable<boolean> {
    return this.appState.loading;
  }

  get user(): Observable<string | null> {
    return this.appState.user;
  }

  get loginGuid(): Observable<string | null> {
    return this.appState.loginGuid;
  }

  get date(): Observable<string> {
    return this.appState.date;
  }

  get isToday(): Observable<boolean> {
    return this.appState.date.pipe(
      map((date) => {
        const newDate = new Date();
        newDate.setFullYear(Number(date.substring(0, 4)));
        newDate.setMonth(Number(date.substring(5, 7)) - 1);
        newDate.setDate(Number(date.substring(8, date.length)));
        newDate.setMinutes(0, 0, 0);

        const today = new Date();
        today.setMinutes(0, 0, 0);
        return newDate.getTime() === today.getTime();
      })
    );
  }

  get isDistantFuture(): Observable<boolean> {
    /*return this.appState.date
      .pipe(map(date => {
        const maxDistance = 1000 * 60 * 60 * 24 * 8;
        const newDate = new Date();
        newDate.setFullYear(Number(date.substring(0, 4)));
        newDate.setMonth(Number(date.substring(5, 7)) - 1);
        newDate.setDate(Number(date.substring(8, date.length)));
        newDate.setMinutes(0, 0, 0);

        const today = new Date();
        today.setMinutes(0, 0, 0);
        return ((newDate.getTime() - today.getTime()) > maxDistance);
      }));*/
    return of(false);
  }

  get sessionsLoading(): Observable<boolean> {
    return this.appState.sessionsLoading;
  }

  get sessions(): Observable<Session[]> {
    return this.appState.sessions;
  }

  get session(): Observable<Session | null> {
    return this.appState.session.pipe(filter((session) => session !== null));
  }

  get bookedSession(): Observable<{ sessionGuid: string; bookingResult: BookingResult } | null> {
    return this.appState.bookedSession.pipe(filter((bookedSession) => bookedSession !== null));
  }

  get fitnessCenters(): Observable<FitnessCenter[]> {
    return this.appState.fitnessCenters;
  }

  get fitnessCenter(): Observable<FitnessCenter | null> {
    return this.appState.selectedFitnessCenter;
  }

  private watchErrors(): void {
    this.appState.error.pipe(filter((error) => error !== null)).subscribe((error) => alert(error));
  }

  private loadFitnessCenters(): void {
    const activationCode = this.appState.activationCode.getValue();
    if (activationCode) {
      this.appState.loading.next(true);
      this.server
        .getCenters(activationCode)
        .then((response) => {
          if (response.Data) {
            this.appState.loading.next(false);
            this.appState.fitnessCenters.next(response.Data.List.ListItem);
          } else if (response.Diagnostics) {
            this.appState.error.next(response.Diagnostics.Diagnostic.Description);
            this.appState.loading.next(false);
          }
        })
        .catch(() => {
          this.appState.error.next('Problem communicating with server');
          this.appState.loading.next(false);
        });
    }
  }

  private loadSessions(): void {
    combineLatest([this.appState.date, this.appState.selectedFitnessCenter])
      .pipe(filter(([date, fitnessCenter]) => !!fitnessCenter && !!date))
      .subscribe(([date, fitnessCenter]) => {
        if (fitnessCenter) {
          this.appState.sessionsLoading.next(true);
          this.server
            .getSessions(fitnessCenter, date)
            .then((response) => {
              if (response.Data) {
                this.appState.sessionsLoading.next(false);
                this.appState.sessions.next(response.Data.List.ListItem);
              } else if (response.Diagnostics) {
                this.appState.error.next(response.Diagnostics.Diagnostic.Description);
                this.appState.sessionsLoading.next(false);
              }
            })
            .catch(() => {
              this.appState.error.next('Problem communicating with server');
              this.appState.sessionsLoading.next(false);
            });
        }
      });
  }

  private convertToDateString(date: Date): string {
    const year: string = date.getFullYear().toString();
    const month: string =
      (date.getMonth() + 1).toString().length === 1 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
    const day: string = date.getDate().toString().length === 1 ? `0${date.getDate().toString()}` : date.getDate().toString();

    return `${year}-${month}-${day}`;
  }

  private convertToDateObject(dateString: string): Date {
    const year = Number(dateString.substring(0, 4));
    const month = Number(dateString.substring(5, 7)) - 1;
    const day = Number(dateString.substring(8, dateString.length));

    const date = new Date();
    date.setFullYear(year);
    date.setMonth(month);
    date.setDate(day);
    date.setMinutes(0, 0, 0);
    return date;
  }
}
