import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';

import { Settings, AppSettings } from '@app/app.settings';

import { FitnessCenterServerData, FitnessCenter, SessionServerData, Session, BookingResultServerData } from '../models';

@Injectable()
export class ServerService {
  private settings: Settings = AppSettings;

  constructor(private http: HttpClient) {}

  getCenters(activationCode: string): Promise<FitnessCenterServerData> {
    const xml = `
      <Message>
        <MessageName>ActivationCodeFind</MessageName>
        <ComponentName>FC.CheckInSystem</ComponentName>
        <Parameters>
          <ActivationCodeGUID>${activationCode}</ActivationCodeGUID>
          <Version></Version>
        </Parameters>
      </Message>
    `;

    return firstValueFrom(this.request(xml));
  }

  getSessions(fitnessCenter: FitnessCenter, date: string): Promise<SessionServerData> {
    const xml: string = (() => {
      if (fitnessCenter.ActivationType === '2') {
        return `
          <Message>
            <MessageName>WebAppTrainingSessionsGet</MessageName>
            <ComponentName>FC.CheckInSystem</ComponentName>
            <Parameters>
              <FitnessCenterGUID>${fitnessCenter.ActivatedGUID}</FitnessCenterGUID>
              <Date>${date}</Date>
            </Parameters>
          </Message>
        `;
      } else {
        return `
          <Message>
            <MessageName>RetentionUserNotificationCalender</MessageName>
            <ComponentName>FC.Retention</ComponentName>
            <Parameters>
              <FitnessCenterUserGUID>${fitnessCenter.ActivatedGUID}</FitnessCenterUserGUID>
              <NotificationDate>${date}</NotificationDate>
            </Parameters>
          </Message>
        `;
      }
    })();

    return firstValueFrom(this.request(xml));
  }

  bookSession(
    session: Session,
    userGuid: string | null,
    loginGuid: string | null,
    pin: string,
    id: string
  ): Promise<BookingResultServerData> {
    const xml: string = (() => {
      if (userGuid && loginGuid) {
        return `
          <Message>
            <MessageName>TrainingSessionBook</MessageName>
            <ComponentName>FC.CheckInSystem</ComponentName>
            <Parameters>
              <TrainingSessionGUID>${session.TrainingSessionGUID}</TrainingSessionGUID>
              <UserGUID>${userGuid}</UserGUID>
              <UserLoginGUID>${loginGuid}</UserLoginGUID>
              <Date>${session.Date}</Date>
              <BookingApplicationGUID></BookingApplicationGUID>
              <Version>1</Version>
            </Parameters>
          </Message>
        `;
      } else {
        return `
          <Message>
            <MessageName>WebAppTrainingSessionBook</MessageName>
            <ComponentName>FC.CheckInSystem</ComponentName>
            <Parameters>
              <TrainingSessionGUID>${session.TrainingSessionGUID}</TrainingSessionGUID>
              <Date>${session.Date}</Date>
              <PinCode>${pin}</PinCode>
              <ID>${id}</ID>
              <BookingApplicationGUID></BookingApplicationGUID>
            </Parameters>
          </Message>
        `;
      }
    })();

    return firstValueFrom(this.request(xml));
  }

  private request(xml: string): Observable<any> {
    return this.http.post<any>(`${this.settings.server}/xml?format=json`, { xml: xml });
  }
}
