import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <div class="fill" fxLayout="column">
      <div class="container" fxFlex>
        <router-outlet></router-outlet>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
          <span class="mat-body-1"> Powered by M3Software - <a href="https://m3fit.se" target="_blank">M3Fit</a> </span>

          <a class="mat-body-1" [href]="desktopUrl" target="_self">Desktop</a>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  get desktopUrl(): string {
    return new URL(location.href).searchParams.get('d')
      ? `https://${location.host.substring(2, location.host.length)}${location.pathname}${location.search}`
      : `https://${location.host.substring(2, location.host.length)}${location.pathname}${location.search}&d=true`;
  }
}
