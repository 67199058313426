import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DiagnosticsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse && event.body.Diagnostics) {
          const response = event.clone();
          response.body.Diagnostics = this.fixDiagnostics(response.body.Diagnostics);
          return response;
        }
        return event;
      })
    );
  }

  private fixDiagnostics(diagnostics: any): any {
    const Diagnostics = {
      Diagnostic: {
        Description: 'Problem communicating with server',
      },
    };
    if (Array.isArray(diagnostics.Diagnostic)) {
      diagnostics.Diagnostic.forEach((d: any) => {
        if (Number(d.Id) > 100) {
          Diagnostics.Diagnostic.Description = d.Description;
        }
      });
    } else {
      if (Number(diagnostics.Diagnostic.Id) > 100) {
        Diagnostics.Diagnostic.Description = diagnostics.Diagnostic.Description;
      }
    }
    return Diagnostics;
  }
}
