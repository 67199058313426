import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LoadingSpinnerComponent } from './components';
import { paramsInitFactory } from './factories';
import { ListInterceptor, DiagnosticsInterceptor } from './interceptors';
import { CreateViewPipe, SubtractPipe, ToNumberPipe } from './pipes';
import { AppStateService, ServerService } from './services';

const angularMaterialModules = [
  MatButtonModule,
  MatDividerModule,
  MatDialogModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
];

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule, ...angularMaterialModules],
  declarations: [LoadingSpinnerComponent, CreateViewPipe, SubtractPipe, ToNumberPipe],
  exports: [TranslateModule, LoadingSpinnerComponent, CreateViewPipe, SubtractPipe, ToNumberPipe, ...angularMaterialModules],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: paramsInitFactory,
          deps: [AppStateService],
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ListInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DiagnosticsInterceptor,
          multi: true,
        },
        TranslateService,
        AppStateService,
        ServerService,
      ],
    };
  }
}
