import { AppSettings } from '@app/app.settings';

import { Language } from '../models';
import { AppStateService } from '../services';

export function paramsInitFactory(appState: AppStateService) {
  return () => {
    const activationCode = new URL(location.href).searchParams.get('a');
    const userGuid = new URL(location.href).searchParams.get('u');
    const loginGuid = new URL(location.href).searchParams.get('lg');
    const language = <Language>new URL(location.href).searchParams.get('l');
    const auth = new URL(location.href).searchParams.get('q');

    if (activationCode) {
      if (auth) {
        if (userGuid && loginGuid) {
          appState.setUser(userGuid);
          appState.setLoginGuid(loginGuid);
          const currentUrl: URL = new URL(location.href);
          const params: URLSearchParams = new URLSearchParams(currentUrl.search);
          params.delete('token');
          params.delete('lg');
          window.history.pushState('', '', `${location.pathname}?${params.toString()}`);
        } else {
          const url = location.href.substring(0, location.href.indexOf('?'));
          const params: URLSearchParams = new URLSearchParams(new URL(location.href).search);
          params.delete('u');
          params.delete('lg');
          const redirectUrl = `${url}?${params.toString()}`;
          location.href = `${AppSettings.authServer}?redirect_uri=${encodeURIComponent(redirectUrl)}&guid=${activationCode}`;
        }
      }
      appState.setActivationCode(activationCode);
    } else {
      appState.setError('Missing Activation Code');
      return Promise.reject('missing_activation_code');
    }

    appState.setLanguage(language);

    return Promise.resolve();
  };
}
