export interface Settings {
  server: string;
  authServer: string;
  registerServer: string;
}

export const AppSettings: Settings = {
  server: 'https://api.m3fit.se/v1',
  authServer: 'https://auth.m3fit.se',
  registerServer: 'https://register.m3fit.se/test-user/',
};
