import { Pipe, PipeTransform } from '@angular/core';

type Context<T> = {
  [K in keyof T]: Exclude<T[K], null>;
};

@Pipe({ name: 'createView' })
export class CreateViewPipe implements PipeTransform {
  transform<T>(value: T): Context<T> {
    return value as Context<T>;
  }
}
