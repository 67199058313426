import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ListInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event) => {
        if (
          event instanceof HttpResponse &&
          event.body.Data &&
          event.body.Data.List &&
          event.body.Data.List.ListItem &&
          !Array.isArray(event.body.Data.List.ListItem)
        ) {
          const response = event.clone();
          response.body.Data = this.fixArrays(response.body.Data);
          return response;
        } else if (event instanceof HttpResponse && event.body.Data && event.body.Data.List === null) {
          const response = event.clone();
          response.body.Data = this.setListValue(response.body.Data);
          return response;
        }
        return event;
      })
    );
  }

  private fixArrays(data: any): any {
    return {
      ...data,
      List: {
        ListItem: [data.List.ListItem],
      },
    };
  }

  private setListValue(data: any): any {
    return {
      ...data,
      List: {
        ListItem: [],
      },
    };
  }
}
