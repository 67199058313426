import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/select-center/select-center.module').then(({ SelectCenterModule }) => SelectCenterModule),
  },
  {
    path: 'select-center',
    loadChildren: () => import('./features/select-center/select-center.module').then(({ SelectCenterModule }) => SelectCenterModule),
  },
  {
    path: ':fitnessCenterGuid/sessions',
    loadChildren: () => import('./features/sessions/sessions.module').then(({ SessionsModule }) => SessionsModule),
  },
  {
    path: '**',
    redirectTo: 'select-center',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutesModule {}
